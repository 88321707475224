@import "../_config/config";

// scope the css for cms pages
body.page-cms {
  text-align: left;

  background-color: white;

  .relative {
    position: relative;
  }

  section,
  .section {
    padding-top: 0;
    padding-bottom: 0;
  }

  .elementor-section-content-top {
    .elementor-image {
      &:after {
        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        bottom: -40px;
        display: block;
        border-radius: 100%;
        height: 50px;
        width: 85%;
        background: #000000;
        opacity: 0.3;
        filter: blur(20px);
      }

      img {
        position: relative;
        z-index: 2;
      }
    }
  }

  a:not(.btn):not(.button):not(.added_to_cart):hover {
    transition: none;
    opacity: 1;
  }

  .bdt-sticky-fixed {
    z-index: 100;
  }

  .bdt-tabs .bdt-switcher-item-content table,
  .elementor-tab-content table {
    width: 100%;
    margin-bottom: 20px;

    th,
    td {
      padding: 10px;
    }

    th {
      padding-top: 0;
    }
  }

  .elementor-widget-container .elementor-text-editor table {
    width: 100%;

    tr:nth-child(odd) {
      background: $grey-lighter;
    }

    td {
      padding: 10px;
    }

    tr:first-child {
      background-color: $font-primary;
      color: $white;
    }
  }

  .elementor-widget.elementor-widget-menu-anchor {
    margin-bottom: 0;
    display: block;
    position: relative;
    top: -80px;
    visibility: hidden;
  }

  .elementor-icon-list-items li.elementor-icon-list-item {
    margin-bottom: 7px;
  }

  .wp-cms-preview-banner {
    width: 100%;
    background-color: yellow;
    text-align: center;
    padding: 8px;
  }
}
